import React, { useState } from "react";
import Layout from "../layout";
import { useStaticQuery, graphql } from "gatsby";
import Github from "../components/github";
import Img from "gatsby-image";
import { Helmet } from "react-helmet";

export default ({ uri }) => {
  const [sortByDiff, setSort] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      chat: file(relativePath: { eq: "chat.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gripurl: file(relativePath: { eq: "gripurl.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      countdown: file(relativePath: { eq: "countdown.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      count: file(relativePath: { eq: "count.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cash: file(relativePath: { eq: "cash.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      daloule: file(relativePath: { eq: "daloule.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tony: file(relativePath: { eq: "tony.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      smulink: file(relativePath: { eq: "smulink.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blog: file(relativePath: { eq: "blog.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const projects = [
    {
      name: "gripURL Link Shortener",
      url: "gripurl.com",
      image: data.gripurl.childImageSharp.fluid,
      text:
        "Free Url shortener. User portal: <b>React</b>. Admin app: <b>Electron</b>. State-management: <b>Context API</b>. Database: <b>MongoDB</b> (<b>mongoose</b>). Url caching: <b>Redis</b>. Authentication: <b>jsonwebtokens</b>. Has companion <b>Chrome Extension</b>. Is <b>PWA</b>.",
      front: "Eta (template-engine), webcomponents, React, Electron.js",
      back: "Node (Express) (Typescript)",
      date: "November 20, 2020",
      diff: 2,
      status: "online",
    },
    {
      name: "Counting App",
      url: null,
      image: data.count.childImageSharp.fluid,
      text:
        "Counting app for iOS and Android using native storage. Keep count of whatever.",
      front: "React Native",
      back: "N/A",
      date: "October 30, 2020",
      diff: 9,
      status: "APK available",
    },
    {
      name: "Countdown App",
      url: null,
      image: data.countdown.childImageSharp.fluid,
      text:
        "Recursive Countdown app for iOS and Android using native animation. Countdown was made to be used as a shot-clock for basketball, but can be used for anything else, including interval training.",
      front: "React Native",
      back: "N/A",
      date: "September 27, 2020",
      diff: 8,
      status: "APK available",
    },
    {
      name: "Chat App",
      url: "fall.chat.network",
      image: data.chat.childImageSharp.fluid,
      text:
        "Private Chat Application. UI: <b>Ionic</b>. State Management: <b>easy-peasy</b>. Real-time Communication: <b>socket.io</b>. Push Notifications: <b>Service Worker</b> + <b>web-push</b>. Database: <b>MongoDB</b>. Authentication: <b>JWT</b>. 100% PWA.",
      front: "React + Easy-Peasy + Ionic",
      back: "Node (Express)",
      date: "July 10, 2020",
      diff: 1,
      status: "Online",
    },
    {
      name: "Waste Money App",
      url: "zen-noyce-501ffa.netlify.app",
      image: data.cash.childImageSharp.fluid,
      text:
        "Web app to waste money. Payment Processing: <b>Stripe API</b>. Database: <b>MongoDB</b>. FaaS: <b>Netlify Functions</b>. UI Animations: <b>React Spring</b>.",
      front: "React",
      back: "Serverless Functions",
      date: "June 21, 2020",
      diff: 6,
      status: "Demo",
    },
    {
      name: "Lifestyle Blog",
      url: null,
      image: data.blog.childImageSharp.fluid,
      text: "Lifestyle Blog made with Django-admin.",
      front: "Jinja",
      back: "Python (Django)",
      date: "June 15, 2020",
      diff: 5,
      status: "",
    },
    {
      name: "Clinique Daloule Website",
      url: "cliniquedaloule.com",
      image: data.daloule.childImageSharp.fluid,
      text: "Website for Clinic. Uses netlify forms. Multi-lang.100% PWA",
      front: "Gatsby (React)",
      back: "N/A",
      date: "Jan 22, 2020",
      diff: 7,
      status: "Online",
    },
    {
      name: "Link Storing Web App",
      url: null,
      image: data.smulink.childImageSharp.fluid,
      text:
        "Store social media links in one place + QR code. UI: <b>Bootstrap</b>. State Management: <b>Redux</b>. Database: <b>MySQL</b>. Media Storage: <b>AWS</b>. Authentication: <b>Django-knox</b> + <b>JWT</b>.",
      front: "React + Redux + Bootstrap",
      back: "Python (Django) + Rest Framework",
      date: "Jan 15, 2020",
      diff: 3,
      status: "Patching",
    },
    {
      name: "Tony Store",
      url: null,
      image: data.tony.childImageSharp.fluid,
      text:
        "E-commerce style website based on reservations. Frontend is raw CSS, and VanillaJS. Backend is all <b>PHP</b>. <b>MySQL</b> for DB.",
      front: "Basics",
      back: "PHP",
      date: "Jan 2, 2020",
      diff: 4,
      status: "Patching",
    },
  ];

  const getOrder = (a, b) => {
    return a.diff - b.diff;
  };
  return (
    <Layout path={uri}>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>Mohamed's Porfolio &#129305;</title>
        <meta
          name="description"
          content="Full Stack Web Developer Portfolio."
        />
        <meta name="robots" content="nofollow" />
      </Helmet>
      <div className="projects fade">
        <p className="random-text">This Year. (since Jan 2020)</p>
        <br />
        <div className="sort">
          Sort By:{" "}
          <select
            onChange={e => setSort(e.target.value === "1")}
            defaultValue="0"
          >
            <option value="0">Recency</option>
            <option value="1">Complexity</option>
          </select>
        </div>
        {!sortByDiff
          ? projects.map((project, i) => (
              <div className="project-container" key={i}>
                <div className="left">
                  <div className="image-container">
                    <Img alt="website-screenshot" fluid={project.image} />
                  </div>
                  <div className="text">
                    <h3>{project.name}</h3>
                    <b>URL:</b>{" "}
                    <a
                      href={project.url && "https://" + project.url}
                      className="normal"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {project.url || "N/A"}
                    </a>
                    <br />
                    <b>Frontend:</b> {project.front}
                    <br />
                    <b>Backend:</b> {project.back}
                    <br />
                    <b>Date:</b> {project.date}
                    <br />
                    <b>Status:</b> {project.status}
                  </div>
                </div>
                <div className="right">
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: `<b>Desc: </b>${project.text}`,
                    }}
                  ></div>
                </div>
              </div>
            ))
          : projects
              .sort((a, b) => getOrder(a, b))
              .map((project, i) => (
                <div className="project-container" key={i}>
                  <div className="left">
                    <div className="image-container">
                      <Img alt="website-screenshot" fluid={project.image} />
                    </div>
                    <div className="text">
                      <b>URL:</b>{" "}
                      <a
                        href={"https://" + project.url}
                        className="normal"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {project.url}
                      </a>
                      <br />
                      <b>Frontend:</b> {project.front}
                      <br />
                      <b>Backend:</b> {project.back}
                      <br />
                      <b>Date:</b> {project.date}
                      <br />
                      <b>Status:</b> {project.status}
                    </div>
                  </div>
                  <div className="right">
                    <h1>{project.name}</h1>
                    <br />
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{ __html: project.text }}
                    ></div>
                  </div>
                </div>
              ))}
        <p>Projects ommited to avoid redundancy...</p>
      </div>
      <Github />
    </Layout>
  );
};
